import React from "react";
import Th from './MKI.png'
const Sem2=[
{
    '_id':2,
    'name':"Achieving Problem- Solution Fit and Product-Market Fit",
    'description':'By: Dr  Shrikant Mulik ',
    'image':Th,
    'duration':'1 DAY-10:00am-12:00pm',
    'fee':0
   
}

]
export default Sem2