import React from "react";
import Th from './MKI.png'
const Sem6=[
    {
        '_id':6,
        'name':"Entrepreneurship and Innovation” as Career Opportunity",
        'description':'By:Dr. Kavita Pandey ',
        'image':Th,
        'duration':'2nd day-2:00pm-4:00pm',
        'fee':0
       
    }    
]
export default Sem6
