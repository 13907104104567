import React from "react";
import i1 from './i1.png'
const autionskills=[
    {
    '_id':3,
    'name':"Auction Skills",
    'description':'Auction Skills at Fiesta are honed through dynamic experiences. Participants engage in strategic bidding, negotiation, and decision-making, cultivating a unique set of skills crucial for successful auction participation.(limited 35)',
    'image':i1,
    'duration':'1 DAYS',
    'fee':51
   
}
]
export default autionskills