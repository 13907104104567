import React from "react"
import MM from './M&MSf.png'
const MMS=[
    {
        '_id':6,
        'name':"Master & Miss Fiesta",
        'description':'Master and Miss Fiesta, a captivating business pageant, celebrates entrepreneurship and innovation. Unveiling talent and vision, its a platform for future business leaders to shine and thrive.',
        'image':MM,
        'duration':'1 DAYS',
        'fee':100
       
    }]
    export default MMS