import React from "react";
import Th from './MKI.png'
const Sem3=[
{
    '_id':3,
    'name':"Lean Start-up & Minimum Viable Product/Business",
    'description':'By:Anthony Colaco ',
    'image':Th,
    'duration':'1 DAY-3:00pm-5:00pm',
    'fee':0
   
}

]
export default Sem3