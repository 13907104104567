import React from "react";
import Th from './MKI.png'
const Elocution=[
{
    '_id':4,
    'name':"ELOCUTION",
    'description':'Elocution is the art of expressive public speaking. It involves clear pronunciation, effective modulation, and captivating delivery, enhancing communication skills and overall oratory proficiency.',
    'image':Th,
    'duration':'1 DAYS',
    'fee':51
   
}
]
export default Elocution
