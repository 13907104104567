import react from 'react'
import Bf from './components/bf.png'
//const navigate = useNavigate();

const courses =
[
{
    '_id':1,
    'name':"BUSSINESS FAIR",
    'description':'The Business Fair at Fiesta is a bustling hub of innovation and collaboration, featuring diverse companies showcasing their products and services, fostering networking, and promoting entrepreneurial spirit.',
    'image':Bf,
    'duration':'2 DAYS',
    'fee':600
   
}

]






export default courses