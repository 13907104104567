import React from "react"
import SS from './S&S.png'
const Sparkstudio =[
    {
        '_id':5,
        'name':"Spark Studio",
        'description':'Spark Studio, an electrifying event at Fiesta, features the thrilling Shark Tank challenge. Entrepreneurs pitch their ideas to savvy investors, igniting sparks of innovation and potential business ventures.',
        'image':SS,
        'duration':'1 DAYS',
        'fee':100,
        
       
    }]
    export default Sparkstudio