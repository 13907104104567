import React from "react";
import Th from './MKI.png'
const Sem5=[
{
    '_id':5,
    'name':"Business Model Canvas  ",
    'description':'By:  Raj Davda ',
    'image':Th,
    'duration':'2nd  DAY-11:30am-1:30pm',
    'fee':0
   
}
]
export default Sem5
