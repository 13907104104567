import React from "react";
import Th from './MKI.png'
const Sems=[
{
    '_id':4,
    'name':"How to plan for Start-up and legal & Ethical Steps ",
    'description':'By: Charudatta Pande',
    'image':Th,
    'duration':'2nd DAY-9:30am-11:00am',
    'fee':0
   
},


]
export default Sems