import React from "react";
import MM from './MK.png'

const MockInterview=[
    {
        '_id':2,
        'name':"Mock Interview",
        'description':'The Mock Interview event at Fiesta provides a simulated professional experience, allowing participants to refine their interview skills. Receive constructive feedback and gain confidence for real-world job opportunities.',
        'image':MM,
        'duration':'2 DAYS',
        'fee':51
        
    
       
    }
]
export default MockInterview