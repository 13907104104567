import React from "react";
import Th from './MKI.png'
const Sem1=[
{
    '_id':4,
    'name':"Problem Solving and Ideation Workshop Helping a Million Entrepreneurs and Professionals accelerate their Growth by using Developing a Digital Mindset",
    'description':'By: Dr. Charudatta Pande',
    'image':Th,
    'duration':'2 DAY-9:00am-11:00Am',
    'fee':'Free'
   
}

]
export default Sem1
